import Rest from '@/services/Rest';

/**
 * @typedef {BrokerNoLoginService}
 */
export default class BrokeNoLoginService extends Rest {
  /**
   * @type {String}
   */
  static resource = 'no-login/broker'
}
