import Rest from '@/services/Rest';

/**
 * @typedef {ImobiliariaNoLoginService}
 */
export default class ImobiliariaNoLoginService extends Rest {
  /**
   * @type {String}
   */
  static resource = 'no-login/imobiliaria'
}
